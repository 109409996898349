import { interceptorFunction } from "lib/interceptor";
import toast from "react-hot-toast";
// import { axiosInstance, } from '../interceptor';

import wait from "../../utils/wait";
let interceptores = interceptorFunction();

const VERSION_FLAG = "V1";
const BASE_URL = "BASE_URL_2";

class DeviceApi {
  async addMoter(data) {
    try {
      return await interceptores.POST("/devices/motors/?uadt=2", data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getMotorPagination(req) {
    try {
      return await interceptores.GET(
        `/devices/motors/?uadt=2&include=${`registers`}&${req}`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async updateMoter(data, id) {
    try {
      return await interceptores.PUT(`/devices/motors/${id}/?uadt=2`, data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getMoter() {
    try {
      return await interceptores.GET(
        "/devices/motors/?uadt=2&include=registers"
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getMoterByid(id) {
    try {
      return await interceptores.GET(`/devices/motors/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async deletMoter(id) {
    try {
      return await interceptores.DELETE(`/devices/motors/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  //  Device Configrstion Api
  async addConfig(data) {
    try {
      return await interceptores.POST("/devices/configurations/?uadt=2", data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getConfigPagination(req) {
    try {
      return await interceptores.GET(`/devices/configurations/?uadt=2&${req}`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getTutorial() {
    try {
      return await interceptores.GET(`/tutorials/?attributes=order|short_code&api_version=new`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async updateConfig(data, id) {
    try {
      return await interceptores.PUT(
        `/devices/configurations/${id}/?uadt=2`,
        data
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getConfig() {
    try {
      return await interceptores.GET("/devices/configurations/?uadt=2");
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getConfigByid(id) {
    try {
      return await interceptores.GET(`/devices/configurations/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async deletConfig(id) {
    try {
      return await interceptores.DELETE(
        `/devices/configurations/${id}/?uadt=2`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  //  Device Types Api
  async addType(data) {
    try {
      return await interceptores.POST("/devices/types/?uadt=2", data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getTypePagination(req) {
    try {
      return await interceptores.GET(`/devices/types/?uadt=2&${req}`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async updateType(data, id) {
    try {
      return await interceptores.PUT(`/devices/types/${id}/?uadt=2`, data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getType() {
    try {
      return await interceptores.GET("/devices/types/?uadt=2");
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getTypeByid(id) {
    try {
      return await interceptores.GET(`/devices/types/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async deletType(id) {
    try {
      return await interceptores.DELETE(`/devices/types/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  //  Device Modol Api
  async addModel(data) {
    try {
      return await interceptores.POST("/devices/models/?uadt=2", data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getModelPagination(req) {
    try {
      return await interceptores.GET(`/devices/models/?uadt=2&${req}`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async updateModel(data, id) {
    try {
      return await interceptores.PUT(`/devices/models/${id}/?uadt=2`, data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getModel() {
    try {
      return await interceptores.GET(
        "/devices/models/?uadt=2&include=device_configuration"
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getModelByid(id) {
    try {
      return await interceptores.GET(`/devices/models/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async deletModel(id) {
    try {
      return await interceptores.DELETE(`/devices/models/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  //  Device Firmware Api
  async addFirmeWare(data) {
    try {
      return await interceptores.POST("/devices/firmwares/?uadt=2", data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async updateFirmeWare(data, id) {
    try {
      return await interceptores.PUT(`/devices/firmwares/${id}/?uadt=2`, data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getFirmWarePagination(req) {
    try {
      return await interceptores.GET(`/devices/firmwares/?uadt=2&${req}`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getFirmeWare() {
    try {
      return await interceptores.GET("/devices/firmwares/?uadt=2");
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getFirmeWareByid(id) {
    try {
      return await interceptores.GET(`/devices/firmwares/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async deletFirmeWare(id) {
    try {
      return await interceptores.DELETE(`/devices/firmwares/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  //  Device SHedule Api
  async updateShedule(data, id) {
    try {
      return await interceptores.PUT(`/devices/schedules/${id}/?uadt=2`, data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getShedulePagination(req) {
    try {
      return await interceptores.GET(`/devices/schedules/?uadt=2&${req}`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getShedule() {
    try {
      return await interceptores.GET("/devices/schedules/?uadt=2");
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  // Users Device Api
  async updateDevicies(data, id) {
    try {
      return await interceptores.PUT(
        `/devices/user-devices/${id}/?uadt=2`,
        data
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async updateV1Devices(data, id) {
    try {
      return await interceptores.POST(`user/add-device`, data, VERSION_FLAG);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async getDevicies() {
    try {
      return await interceptores.GET("/devices/user-devices/?uadt=2");
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getDeviciesByid(id) {
    try {
      return await interceptores.GET(`/devices/user-devices/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async getV1DevicesById(data) {
    try {
      return await interceptores.POST(
        `user/get-devicedetail`,
        data,
        VERSION_FLAG
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async deletDevicies(id) {
    try {
      return await interceptores.DELETE(`/devices/user-devices/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async deleteV1Devices(data) {
    try {
      // return await interceptores.DELETE(`user/delete-device`);
      return await interceptores.POST(`user/delete-device`, data, VERSION_FLAG);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async shareDevicies(data, id) {
    try {
      return await interceptores.POST(
        `/devices/user-devices/${id}/share-access/?uadt=2`,
        data
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async getTimeZone() {
    try {
      return await interceptores.GET(
        `/basics/timezones/?uadt=2&size=1000&page=0`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async getV1TimeZones(data = {}) {
    try {
      return await interceptores.POST(`user/timezones`, data, VERSION_FLAG);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async addRelaysDevicies(data) {
    try {
      return await interceptores.POST(`/devices/relays/?uadt=2`, data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async addRelaysDeviciesV1(data) {
    try {
      return await interceptores.POST(`user/set-relay`, data, VERSION_FLAG);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async lockRelaysDeviciesV1(data) {
    try {
      return await interceptores.POST(
        `user/lock-unlockrelay`,
        data,
        VERSION_FLAG
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async updateRelaysDevicies(data, id) {
    try {
      return await interceptores.PUT(`/devices/relays/${id}/?uadt=2`, data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getRelaysDeviciesAdmin(id) {
    try {
      return await interceptores.GET(
        `/devices/relays/?uadt=2&device_id__contains=${id}`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async getRelaysDevicies(id) {
    try {
      return await interceptores.GET(`/devices/relays/?uadt=2&device_id=${id}`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  // Payload: {
  //   user_id: USER_ID,
  //   device_id: DEVICE_ID,
  //   language: ISO_LANGUAGE_CODE,
  // }

  async getRelaysOfV1Devices(data) {
    try {
      return await interceptores.POST(
        `user/get-relay-list`,
        data,
        VERSION_FLAG
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async getRelaysDeviciesByid(id) {
    try {
      return await interceptores.GET(`/devices/relays/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async deletRelaysDevicies(id) {
    try {
      return await interceptores.DELETE(`/devices/relays/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async deleteV1Relay(body) {
    try {
      return await interceptores.POST(`user/delete-relay`, body, VERSION_FLAG);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async addShedulesDevicies(data) {
    try {
      return await interceptores.POST(`/devices/schedules/?uadt=2`, data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async updateShedulesDevicies(data, id) {
    try {
      return await interceptores.PUT(`/devices/schedules/${id}/?uadt=2`, data);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async getShedulesDevicies(id) {
    try {
      return await interceptores.GET(
        `/devices/schedules/?uadt=2&relay_id=${id}`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getShedulesDeviciesAll(id) {
    try {
      return await interceptores.GET(
        `/devices/schedules/?uadt=2&relay_id=${id}`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async getShedulesDeviciesByid(id) {
    try {
      return await interceptores.GET(`/devices/schedules/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async deletShedulesDevicies(id) {
    try {
      return await interceptores.DELETE(`/devices/schedules/${id}/?uadt=2`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  async enableAllShedule(id) {
    try {
      return await interceptores.GET(
        `/devices/relays/${id}/enable-all-schedules/?uadt=2`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async getLanguage(page, size, search) {
    try {
      return await interceptores.GET(
        `/basics/languages/languages?attributes=title|lang_code|version&status__in=1&page=${page}&size=${size}&title__contains=${search}`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async getLanguageObject(language) {
    try {
      return await interceptores.GET(
        `/basics/languages/languages/by-lang-code?lang_code__is=${language}&check_version__is=2&used_for__is=WEB`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
  

  async getLanguageKey(page, size, order, orderBy, search) {
    try {
      return await interceptores.GET(
        `/basics/languages/language-keys?sort=${orderBy}-${order.toUpperCase()}&page=${page}&size=${size}&key__contains=${search}`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async getLanguageText(page, size, search) {
    try {
      const data = {
        page: page,
        size: size,
        title__contains: search.title__contains,
        text__contains: search.text__contains,
        key__contains: search.languagekey__contains,
      }
      // Convert object to query parameters
const queryParams = new URLSearchParams(
  Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== '' && value !== null) acc[key] = value; // Only add non-empty values
    return acc;
  }, {} as Record<string, string>)
).toString();

      return await interceptores.GET(
        `/basics/languages/language-texts/table-view?${queryParams}`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }


  async getAllLanguage() {
    try {
      return await interceptores.GET(
        `/basics/languages/language-master?attributes=id|title|lang_code&page=0&size=1000`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async getAllTextCategory() {
    try {
      return await interceptores.GET(
        `/basics/languages/language-keys/constants`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }


  async exportLanguage() {
    try {
      return await interceptores.GET(
        `/basics/languages/languages/export`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async importLanguage(file) {
    try {
      return await interceptores.POST(
        `/basics/languages/languages/import`, file
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }


  async CreateLanguage(body) {
    try {
      return await interceptores.POST(
        `/basics/languages/languages/`, body
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async CreateLanguageKey(body) {
    try {
      return await interceptores.POST(
        `/basics/languages/language-keys/`, body
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async UpdateLanguageKey(id, body) {
    try {
      return await interceptores.PUT(
        `/basics/languages/language-keys/${id}`, body
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async CreateLanguageText(body) {
    try {
      return await interceptores.POST(
        `/basics/languages/language-texts`, body
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async UpdateLanguageText(id, body) {
    try {
      return await interceptores.PUT(
        `/basics/languages/language-texts/update-multiple`, body
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }



  async changeLanguageKeyStatus(id, body) {
    try {
      return await interceptores.PUT(
        `/basics/languages/language-keys/${id}`, body
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async changeLanguageStatus(id, body) {
    try {
      return await interceptores.PUT(
        `/basics/languages/languages/${id}`, body
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }


  async DeleteLanguage(id) {
    try {
      return await interceptores.DELETE(
        `/basics/languages/languages/${id}`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }



  async DeleteLanguageKey(id) {
    try {
      return await interceptores.DELETE(
        `/basics/languages/language-keys/${id}`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async DeleteLanguageText(id) {
    try {
      return await interceptores.DELETE(
        `/basics/languages/language-texts/${id}`);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async disableAllShedule(id) {
    try {
      return await interceptores.GET(
        `/devices/relays/${id}/disable-all-schedules/?uadt=2`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async getTocList() {
    try {
      return await interceptores.GET(
        `/cms/toc/?api_version=new`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async updateToc(data, id) {
    try {
      return await interceptores.PUT(
        `/cms/toc/${id}?api_version=new`, data
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }



  async firmwareUpdate() {
    try {
      return await interceptores.GET(
        `/devices/firmwares/device-fw-updates?uadt=2`
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  // http://192.168.100.95:5032/changetimer
  // http://192.168.100.95:5032/changestatus

  // device_number: Hdjdj;
  // relay_number: 2;
  // status: 1;
  // device_id: 1164;

  async changePortStatusV1(data) {
    try {
      return await interceptores.POST(
        `changestatus`,
        data,
        VERSION_FLAG,
        BASE_URL
      );
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }

  async setActivities(data) {
    try {
      return await interceptores.POST(`user/set-activity`, data, VERSION_FLAG);
    } catch (e) {
      toast.error(
        e.response ? e.response?.data?.message : "something wents to wrong"
      );
      return e;
    }
  }
}

export const devicehApi = new DeviceApi();
