import { Button } from "@material-ui/core";
import { useSettings } from "hooks";
import PropTypes from "prop-types";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

interface BtnProps {
  onClick: () => void;
  [key: string]: any;
  label?: string;
}

const CancelButton: FC<BtnProps> = ({ onClick, label, ...others }) => {
  const {language} = useSettings()

  return (
    <Button
      color="primary"
      sx={{ mt: 1 }}
      variant="outlined"
      onClick={onClick}
      className="commanBtnStyle"
      {...others}
      
    >
      {label || language.webInfo_cancel}
    </Button>
  );
};

CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CancelButton;
