import { TextField, Box, InputAdornment } from "@material-ui/core";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import ToolTipField from "components/Form/ToolTip/ToolTip";
import { InfoOutlined } from "@material-ui/icons";
import InformationDialog from "components/Dialogs/InformationDialog";

interface TextFieldProps {
  label?: string;
  name?: string;
  value?: string | number;
  children?: ReactNode;
  [key: string]: any;
  tooltip?: boolean;
  variant?: "standard" | "filled" | "outlined";
  toolTipMsg?: string;
  infoDiloag?: boolean;
  infotitle?: string;
  infocontent?: string;
  infoicon?: string;
  infodynamicLink?: boolean;
  infooutLined?: boolean;
}

const SquareTextField: FC<TextFieldProps> = ({
  label,
  name,
  value,
  children,
  tooltip,
  toolTipMsg,
  variant,
  infoDiloag,
  infotitle,
  infocontent,
  infoicon,
  infodynamicLink,
  infooutLined,
  ...other
}) => {
  // console.log("Tool tip message :::::::: ", toolTipMsg);

  return (
    <Box display="flex" alignItems="end" justifyContent="space-between">
      <TextField
        fullWidth
        label={label}
        name={name}
        value={value}
        variant={variant ?? "standard"}
        // InputLabelProps={{
        //   shrink: true,
        // }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {tooltip && (
                <ToolTipField
                  // sx={{ ml: 1, mb: "7px" }}
                  sx={{ m: 1 }}
                  title={
                    toolTipMsg
                      ? // ? toolTipMsg ?? ""
                        toolTipMsg || ""
                      : ""
                  }
                >
                  <InfoOutlined fontSize="small" />
                </ToolTipField>
              )}
              {infoDiloag && (
                <InformationDialog
                  title={infotitle}
                  content={infocontent}
                  icon={infoicon}
                  dynamicLink={infodynamicLink}
                  outLined={true}
                />
              )}
            </InputAdornment>
          ),
        }}
        {...other}
      >
        {children}
      </TextField>
      {/* <Box>
        {tooltip && (
          <ToolTipField
            sx={{ ml: 1, mb: "7px" }}
            title={
              toolTipMsg
                ? toolTipMsg ?? ""
                : "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            }
          >
            <InfoOutlined fontSize="small" />
          </ToolTipField>
        )}
      </Box> */}
    </Box>
  );
};

SquareTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  other: PropTypes.shape({}),
  children: PropTypes.node,
};

export default SquareTextField;
