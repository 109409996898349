import { Box, FormHelperText, Typography } from "@material-ui/core";
import CancelButton from "components/Form/button/CancelButton";
import SubmitButton from "components/Form/button/SubmitButton";
import SquareTextField from "components/Form/TextField/SquareTextField";
import { ca } from "date-fns/locale";
import { Formik } from "formik";
import { useAuth, useMounted, useSettings } from "hooks";
import { authApi } from "lib/API/authApi";
import type { FC } from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import OtpInput from "react-otp-input";

const Totp: FC = (props) => {
  const mounted = useMounted();
  const {language} = useSettings()
  const { login, verifyTOTP, isTOTPVerified, MFAUserId, MFAType } = useAuth();
  const [src, setSrc] = useState("");
  const [wrongOtp, setWrongOtp] = useState(false);
  const handleOtpSend = async () => {
    let res = await authApi.resendOtp(MFAUserId);
    if (res?.status === 200 || res?.status === 201) {
      toast.success(res?.data?.message);
      setWrongOtp(false);
    } else {
      toast.error(res?.data?.message ?? "");
      setWrongOtp(true);
    }
  };
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  // console.log("MFAType", MFAType);

  return (
    <>
      <Formik
        initialValues={{
          code: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required("OTP is required"),
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting }
        ): Promise<void> => {
          try {
            await verifyTOTP(values.code.toString(), MFAUserId);
            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (mounted.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }): JSX.Element => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...props}
            className={`otpCustomStyle`}
          >
            {/* <SquareTextField
              label="Enter OTP/Verification Code"
              name="code"
              id="code"
              value={values.code}
              autoFocus
              onChange={handleChange}
              type="text"
              style={{ margin: "20px 0 0" }}
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
              onBlur={handleBlur}
            /> */}
            <Typography variant="subtitle2" sx={{ my: 2 }}>
              Enter OTP/Verification Code
            </Typography>
            <OtpInput
              // placeholder=""
              isInputNum={true}
              hasErrored={wrongOtp}
              value={values.code}
              onChange={(data) => {
                setFieldValue("code", data);
              }}
              numInputs={6}
              containerStyle={`otpCustomStyleInput`}
              separator={
                <span style={{ marginLeft: "10px", marginRight: "10px" }}>-</span>
              }
            />
            {errors.code && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText sx={{ textAlign: "center" }} error>
                  {errors.code}
                </FormHelperText>
              </Box>
            )}
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            {MFAType !== 1 && (
              <Box sx={{ mt: "20px", textAlign: "center" }}>
                {minutes === 0 && seconds === 0 ? (
                  <Box>
                    <CancelButton
                      label={language.webInfo_resendOtp}
                      onClick={() => {
                        handleOtpSend();
                      }}
                    />
                  </Box>
                ) : (
                  <Typography variant="subtitle2" color={"#909090"}>
                    Get new OTP in: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                  </Typography>
                )}
              </Box>
            )}

            <Box sx={{ mt: 2, textAlign: "center" }}>
              <SubmitButton disabled={isSubmitting} label={language.webInfo_login} size="large" />
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Totp;
