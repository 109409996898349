import { createContext, useEffect, useState, useReducer } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { THEMES } from "config/constants";

export interface languageProps {
  id: number;
  name: string;
  iso_code: string;
  status: number;
  createdAt: string;
  updatedAt: string;
}
interface Settings {
  compact?: boolean;
  direction?: "ltr" | "rtl";
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
  language?: "english" | "spanish" | "german" | "french";
  languageList?: Array<languageProps>;
  setLoading?: boolean;
}

export interface SettingsContextValue {
  settings: Settings;
  loading: boolean;
  language: any,
  deviceFirmwareUpdate: boolean;
  firmwareInitalized: boolean;
  setuserInitalized: (data: boolean) => void;
  firmwareDownloading: boolean;
  setuserDownloading: (data: boolean) => void;
  firmwareFinished: boolean;
  setuserFininshed: (data: boolean) => void;
  firmwareFailed: boolean;
  setuserFailed: (data: boolean) => void;
  firmwareTerminated: boolean;
  setuserTerminated: (data: boolean) => void;
  saveSettings: (update: Settings) => void;
  setDeviceSettingsUpdateLoader: (data: boolean) => void;
  setLoadingState: (data: boolean) => void;
  setLanguageObj: (data: boolean) => void;
}

interface SettingsProviderProps {
  children?: ReactNode;
}

const initialSettings: Settings = {
  compact: true,
  direction: "ltr",
  responsiveFontSizes: true,
  roundedCorners: true,
  theme: THEMES.LIGHT,
  language: "english",
  languageList: [],
  setLoading: false,
};

export const restoreSettings = (): Settings | null => {
  let settings = null;

  try {
    const storedData: string | null = window.localStorage.getItem("settings");
    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = {
        compact: true,
        direction: "ltr",
        responsiveFontSizes: true,
        roundedCorners: true,
        theme: window.matchMedia("(prefers-color-scheme: dark)").matches
          ? THEMES.DARK
          : THEMES.LIGHT,
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = (settings: Settings): void => {
  window.localStorage.setItem(
    "settings",
    JSON.stringify({ ...settings, language: "english" })
  );
};

const SettingsContext = createContext<SettingsContextValue>({
  settings: initialSettings,
  loading: false,
  deviceFirmwareUpdate: false,
  saveSettings: () => {},
  setLoadingState: () => {},
  setDeviceSettingsUpdateLoader: () => {},
  firmwareInitalized: false,
  setuserInitalized: () => {},
  firmwareDownloading: false,
  setuserDownloading: () => {},
  firmwareFinished: false,
  setuserFininshed: () => {},
  firmwareFailed: false,
  setuserFailed: () => {},
  firmwareTerminated: false,
  setuserTerminated: () => {},
  setLanguageObj: () => {},
  language: {}
});
export interface setLoadingDta {
  type: "SET_LOADER";
  payload: boolean;
}
export type Action = setLoadingDta;
const handlers: Record<string, (state: Settings, action: Action) => Settings> =
  {
    SET_LOADER: (state: Settings, action: setLoadingDta): Settings => {
      return {
        ...state,
        setLoading: action.payload,
      };
    },
  };

const reducer = (state: Settings, action: Action): Settings =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState<Settings>(initialSettings);
  const [firmwareInitalized, setFirmwareInitalized] = useState<boolean>(false);
  const [firmwareDownloading, setFirmwareDownloading] =
    useState<boolean>(false);
  const [firmwareFinished, setFirmwareFinished] = useState<boolean>(false);
  const [firmwareFailed, setFirmwareFailed] = useState<boolean>(false);
  const [firmwareTerminated, setFirmwareTerminated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<any>({});

  const [state, dispatch] = useReducer(reducer, initialSettings);
  const [deviceFirmwareUpdate, setDeviceFirmwareUpdate] =
    useState<boolean>(false);
  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings(restoredSettings);
    }
  }, []);

  const saveSettings = (updatedSettings: Settings): void => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  const setLoadingState = (data) => {
    setLoading(data);
    dispatch({ type: "SET_LOADER", payload: data });
  };

  const setLanguageObj = (data) => {
    setLanguage(data);
  };

  const setDeviceSettingsUpdateLoader = (data) => {
    setDeviceFirmwareUpdate(data);
  };
  const setuserInitalized = (data) => {
    setFirmwareInitalized(data);
  };
  const setuserDownloading = (data) => {
    setFirmwareDownloading(data);
  };
  const setuserFininshed = (data) => {
    setFirmwareFinished(data);
  };
  const setuserFailed = (data) => {
    setFirmwareFailed(data);
  };
  const setuserTerminated = (data) => {
    setFirmwareTerminated(data);
  };

  return (
    <SettingsContext.Provider
      value={{
        ...state,
        settings,
        deviceFirmwareUpdate,
        setDeviceSettingsUpdateLoader,
        loading,
        saveSettings,
        setLoadingState,
        firmwareInitalized,
        setuserInitalized,
        firmwareDownloading,
        setuserDownloading,
        firmwareFinished,
        setuserFininshed,
        firmwareFailed,
        setuserFailed,
        firmwareTerminated,
        setuserTerminated,
        setLanguageObj,
        language
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
