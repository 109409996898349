import type { Theme } from "@material-ui/core";
import { Box, Divider, Drawer, List } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SVG from "react-inlinesvg";

import {
  DashboardOutlined as Dashboard,
  EmojiObjectsOutlined as EmojiObjects,
  EqualizerOutlined as Equalizer,
  FeedbackOutlined as Feedback,
  HelpOutlineOutlined as Help,
  History,
  ImageOutlined as Image,
  InfoOutlined as Info,
  MailOutlineOutlined as Email,
  NotificationsNoneOutlined as Notifications,
  PeopleOutline,
  PersonOutlineOutlined as Person,
  PhonelinkRing,
  ReorderOutlined as Notes,
  ScheduleOutlined as Schedule,
  SearchOutlined as Search,
  SettingsOutlined as Settings,
  WorkOutlineOutlined,
  SortOutlined,
  ConfirmationNumberOutlined,
} from "@material-ui/icons";
import NavSection from "components/Navbar/NavSection";
import { USER_ROLES } from "config/constants";
import { useSettings, useUser } from "hooks";
import PropTypes from "prop-types";
import type { FC } from "react";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Logo from "../Logo";
import Scrollbar from "../Scrollbar";
import { useTranslation } from "react-i18next";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = (user: any,language:any) => [
  {
    title: "",
    allow: !!(
      user && [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
    ),
    items: [
      {
        title: language.webInfo_dashboard,
        path: "/",
        icon: <Dashboard fontSize="small" />,
        allow: true,
      },
    ],
  },
  {
    title: language.webInfo_management,
    icon: <WorkOutlineOutlined fontSize="small" />,
    allow: true,
    items: [
      {
        title: language.webInfo_userDataManagement,
        // path: "/management/user-management",
        icon: <WorkOutlineOutlined fontSize="small" />,
        allow: !!(
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
        ),
        children: [
          {
            title: language.webInfo_userManagement,
            path: "/management/user-management",
            icon: <PeopleOutline fontSize="small" />,
            allow: !!(
              user &&
              [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
            ),
          },
          {
            title: language.webInfo_userEmailsManagement,
            path: "/management/user-email-management",
            icon: <PeopleOutline fontSize="small" />,
            allow: !!(user && [USER_ROLES.ADMIN].includes(user?.profile?.role?.id)),
          },
          {
            title: language.webInfo_subAdminManagement,
            path: "/management/sub-admin-management",
            icon: <Person fontSize="small" />,
            allow: !!(user && [USER_ROLES.ADMIN].includes(user?.profile?.role?.id)),
          },
        ],
      },
      {
        title: language.webInfo_profileManagement,
        path: "/profile-management",
        icon: <Person fontSize="small" />,
        allow: !!(user && [USER_ROLES.USER].includes(user?.profile?.role?.id)),
      },
      {
        title: language.webInfo_deviceManagement,
        path: "/management/device-management",
        icon: <PhonelinkRing fontSize="small" />,
        allow: !!(user && [USER_ROLES.USER].includes(user?.profile?.role?.id)),
      },
      // {
      //   title: language.webInfo_ledManagement,
      //   icon: <WorkOutlineOutlined fontSize="small" />,
      //   // path: "/management/ledcode-management",
      //   allow: !!(
      //     user &&
      //     [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
      //   ),
      //   children: [
      //     {
      //       title: language.webInfo_ledCodeManagement,
      //       icon: <EmojiObjects fontSize="small" />,
      //       path: "/management/ledcode-management",
      //       allow: !!(
      //         user &&
      //         [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
      //       ),
      //     },
      //     {
      //       title: language.webInfo_sortLedCodes,
      //       path: "/management/ledcode-sort",
      //       icon: <SortOutlined fontSize="small" />,
      //       allow: !!(
      //         user &&
      //         [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
      //       ),
      //     },
      //   ],
      // },
      {
        title: language.webInfo_tutorialManagement,
        // path: "/management/tutorials",
        icon: <WorkOutlineOutlined fontSize="small" />,
        allow: !!(
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
        ),
        children: [
          {
            title: language.webInfo_tutorialManagement,
            path: "/management/tutorials",
            icon: (
              <SVG
                src="/static/icons/tutorial.svg"
                width="20px"
                height="20px"
                className="MuiSvgIcon-root"
              />
            ),
            allow: !!(
              user &&
              [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
            ),
          },
          {
            title: language.webInfo_sortTutorials,
            path: "/management/sort-tutorial",
            icon: <SortOutlined fontSize="small" />,
            allow: !!(
              user &&
              [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
            ),
          },
        ],
      },

      {
        title: language.webInfo_scheduleManagement,
        path: "/management/schedule-management",
        icon: <Schedule fontSize="small" />,
        allow: !!(user && [USER_ROLES.ADMIN].includes(user?.profile?.role?.id)),
      },
      {
        title: language.webinfo_language_management,
        // path: "/management/tutorials",
        icon: <WorkOutlineOutlined fontSize="small" />,
        allow: !!(
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
        ),
        children: [
          {
            title: language.webInfo_language,
            path: "/management/language",
            icon: (
              <SVG
                src="/static/icons/tutorial.svg"
                width="20px"
                height="20px"
                className="MuiSvgIcon-root"
              />
            ),
            allow: !!(
              user &&
              [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
            ),
          },
          {
            title: language.webInfo_languageKey,
            path: "/management/language-key",
            icon: (
              <SVG
                src="/static/icons/tutorial.svg"
                width="20px"
                height="20px"
                className="MuiSvgIcon-root"
              />
            ),
            allow: !!(
              user &&
              [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
            ),
          },
          {
            title: language.webInfo_languageText,
            path: "/management/language-text",
            icon: (
              <SVG
                src="/static/icons/tutorial.svg"
                width="20px"
                height="20px"
                className="MuiSvgIcon-root"
              />
            ),
            allow: !!(
              user &&
              [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
            ),
          },
          
        ],
      },

     
      {
        title: language.webInfo_infoPointManagement,
        path: "/management/info-point-management",
        icon: <Info fontSize="small" />,
        allow: !!(user && [USER_ROLES.ADMIN].includes(user?.profile?.role?.id)),
      },
    ],
  },

  {
    title: language.webInfo_deviceManagement,
    icon: <WorkOutlineOutlined fontSize="small" />,
    allow: !!(user && [USER_ROLES.ADMIN].includes(user?.profile?.role?.id)),
    items: [
      {
        title: language.webInfo_deviceConfiguration,
        path: "/device-management/device-configuration",
        icon: (
          <SVG
            src="/static/icons/deviceConfigration.svg"
            width="20px"
            height="20px"
            className="MuiSvgIcon-root"
          />
        ),
        iconType: "outined",
        allow: !!(user && [USER_ROLES.ADMIN].includes(user?.profile?.role?.id)),
      },
      {
        title: language.webInfo_motorManagement,
        path: "/device-management/motor-management",
        iconType: "outined",
        icon: (
          <SVG
            src="/static/icons/devicemotor.svg"
            width="20px"
            height="20px"
            className="MuiSvgIcon-root"
          />
        ),
        allow: !!(
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
        ),
      },
      {
        title: language.webInfo_deviceTypes,
        path: "/device-management/device-types",
        iconType: "outined",
        icon: (
          <SVG
            src="/static/icons/deviceType.svg"
            width="20px"
            height="20px"
            className="MuiSvgIcon-root"
          />
        ),
        allow: !!(user && [USER_ROLES.ADMIN].includes(user?.profile?.role?.id)),
      },
      {
        title: language.webInfo_deviceModels,
        path: "/device-management/device-models",
        iconType: "outined",
        icon: (
          <SVG
            src="/static/icons/deviceModel.svg"
            width="20px"
            height="20px"
            className="MuiSvgIcon-root"
          />
        ),
        allow: !!(user && [USER_ROLES.ADMIN].includes(user?.profile?.role?.id)),
      },
      {
        title: language.webInfo_deviceFirmwares,
        path: "/device-management/device-firmwares",
        iconType: "outined",
        icon: (
          <SVG
            src="/static/icons/deviceFirmware.svg"
            width="20px"
            height="20px"
            className="MuiSvgIcon-root"
          />
        ),
        allow: !!(user && [USER_ROLES.ADMIN].includes(user?.profile?.role?.id)),
      },
    ],
  },
  {
    title: language.webInfo_auditTrail,
    icon: <Search fontSize="small" />,
    allow: !!(
      user && [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
    ),
    items: [
      {
        title: language.webinfo_trail,
        path: "/audit-trail/trail",
        icon: <History fontSize="small" />,
        allow: !!(
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
        ),
      },
    ],
  },
  {
    title: language.webInfo_userReport,
    icon: <Search fontSize="small" />,
    allow: !!(
      user && [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
    ),
    items: [
      {
        title: language.webInfo_activeUserReport,
        path: "/report/active-user-report",
        icon: <Equalizer fontSize="small" />,
        allow: !!(
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
        ),
      },
      {
        title: language.webInfo_deviceActivityLog,
        path: "/report/activity-log",
        icon: <History fontSize="small" />,
        allow: !!(
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
        ),
      },
      {
        title: language.webInfo_userSessions,
        path: "/report/user-session",
        icon: <Person fontSize="small" />,
        allow: !!(
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
        ),
      },
    ],
  },
  {
    title: language.webInfo_generalSettings,
    allow: true,
    items: [
      {
        title: language.webInfo_termsAndCondition,
        path: "/terms-and-condition",
        icon: <Email fontSize="small" />,
        allow: !!(
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
        ),
      },
      {
        title:
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
            ? language.webInfo_helpLinks
            : language.webInfo_help,
        path: "/help",
        icon: <Help fontSize="small" />,
        allow: !!(
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN, USER_ROLES.USER].includes(
            user?.profile?.role?.id
          )
        ),
      },
      {
        title: language.webInfo_feedback,
        icon: <Feedback fontSize="small" />,
        path: "/feedback",
        allow: !!(
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
        ),
      },
      // {
      //   title: language.webInfo_dashboardImages,
      //   path: "/dashboard-images",
      //   icon: <Image fontSize="small" />,
      //   allow: !!(
      //     user &&
      //     [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
      //   ),
      // },
      {
        title: language.webInfo_settings,
        path: "/settings",
        icon: <Settings fontSize="small" />,
        allow: !!(
          user && [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN, USER_ROLES.USER].includes(user?.profile?.role?.id)
        ),
      },
    ],
  },
  {
    title: "",
    allow: !!(
      user && [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
    ),
    items: [
      {
        title: language.webInfo_notifications,
        path: "/notifications",
        icon: <Notifications fontSize="small" />,
        allow: !!(
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(user?.profile?.role?.id)
        ),
      },
    ],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useUser();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const {language} = useSettings()
  const updatedSections = sections(user,language);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <List
            sx={{
              "& + &": {
                mt: 3,
              },
            }}
          >
            {updatedSections &&
              updatedSections.map((section, index) => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  {...section}
                  index={index}
                />
              ))}
          </List>
        </Box>
      </Scrollbar>
    </Box>
  );
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 80px) !important",
            top: "80px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
