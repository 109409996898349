// import 'dotenv/config'
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import RTL from "components/RTL";
// import SettingsDrawer from 'components/SettingsDrawer';
import SplashScreen from "components/SplashScreen";
import { useAuth, useScrollReset, useSettings, useUser, useUtils } from "hooks";
import { FC, useState } from "react";
import { useEffect } from "react";
// import { useRoutes } from 'hookrouter';
import { Toaster } from "react-hot-toast";
import { useRoutes, useNavigate, useLocation } from "react-router-dom";
import { gtmConfig } from "./config";
import gtm from "./lib/gtm";
import routes from "./routes";
import { createCustomTheme } from "./theme";
import Notifications from "./config/firebaseNotifactionGet";
import { onMessageListener } from "./config/FirebaseSetup";
import { isSafari } from "@firebase/util";
import { readCookie, clearStorage } from "utils/general";
import Loder from "components/Loader/loader";
import { devicehApi } from "lib/API/deviceApi";
import { DEFAULT_LAN } from "config/constants";
// import showNotification from "utils/showNotification";

// setInterval(() => {
//   let [token, refreshToken, sessionTime] = readCookie();
//   try {
//     if (token) {
//       const cTime = new Date().getTime();
//       //sessionTime =  new Date(parseInt(sessionTime)).getTime();
//       sessionTime = JSON.parse(sessionTime)["sessionTime"];
//       if (typeof sessionTime == "string") {
//         sessionTime = new Date(sessionTime).getTime();
//       }
//       if (cTime > sessionTime) {
//         clearStorage();
//         window.location.href = "/";
//       }
//     }
//   } catch (e) {
//     clearStorage();
//     window.location.href = "/";
//   }
// }, 10000);

const App: FC = () => {
  const { isInitialized, isAuthenticated } = useAuth();
  const { user } = useUser();
  const content = useRoutes(routes(user));
  const { isModalOpen, modelContent } = useUtils();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const { settings, loading, setLanguageObj } = useSettings();
  const navigate = useNavigate();
  const location = useLocation();
  let isSafarTest = false;
  useScrollReset();
  useEffect(() => {
    gtm.initialize(gtmConfig);
    isSafarTest = isSafari();
  }, []);
  useEffect(() => {
    if (
      user &&
      user.profile.role_id >= 3 &&
      (!user.profile.is_profile_updated ||
        user.profile.is_profile_updated === undefined ||
        user.profile.is_profile_updated === 0)
    ) {
      navigate("/profile-management");
    }
  }, [location.pathname]);
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  onMessageListener()
    .then((payload: any) => {
      // console.log("Message", payload);
      new Notification(payload.notification.title, {
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));
  useEffect(() => {
    if (isAuthenticated) {
      if ("Notification" in window && Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          // console.log("Permissions", permission);
          if (Notification.permission === "granted") {
            setShow(true);
          }
        });
      }
    } else {
      return;
    }
  }, [isAuthenticated]);
  //  console.log("Setting Update", loading);

  const getLanguage = async (language) => {
    if (language) {
      const response = await devicehApi.getLanguageObject(language);
      if (response.data.status.code === 200) {
        console.log("response?.data?.data", response?.data?.data)
        setLanguageObj(response?.data?.data)
      }
    }

  }

  const language = localStorage.getItem("language")

  useEffect(() => {
    getLanguage(language ? language : DEFAULT_LAN)
  }, [language])

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster position="top-center" />
        {"Notification" in window && !isSafarTest && isAuthenticated && (
          <Notifications />
        )}
        {/* <SettingsDrawer /> */}
        {loading && <Loder isLoding={loading} />}

        {isInitialized ? content : <SplashScreen />}
        {isModalOpen ? modelContent : null}
      </RTL>
    </ThemeProvider>
    // <button onClick={() => { throw Error(" test err. Sentry init err."); }}>Break the world</button>
  );
};

export default App;
