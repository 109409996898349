import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OutlineButton from 'components/Form/button/OutlineButton';
import { useSettings } from 'hooks';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

const CancelAndBackButton: FC<{ to: string }> = ({ to }) => {
  const {language} = useSettings()
  return (
    <OutlineButton
      label={language.webInfo_cancel}
      to={to}
      startIcon={<ArrowBackIcon fontSize="small" />}
      sx={{
        float: 'right',
        textTransform: 'capitalize'
      }}
    />
  );
};

CancelAndBackButton.propTypes = {
  to: PropTypes.string.isRequired,
};

export default CancelAndBackButton;
