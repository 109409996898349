import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { Info, InfoOutlined } from "@material-ui/icons";
import SubmitButton from "components/Form/button/SubmitButton";
import { useSettings } from "hooks";
import PropTypes from "prop-types";
import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface DialogProps {
  title: string;
  content: string;
  icon: string;
  outLined?: boolean;
  dynamicLink?: boolean;
}

const InformationDialog: FC<DialogProps> = ({
  title,
  content,
  icon,
  outLined,
  dynamicLink,
}) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // console.log("Props Info tag", title, content, icon);
  const {language} = useSettings()

  return (
    <>
      <IconButton color="inherit" onClick={handleClickOpen} sx={{ p: 0 }}>
        {outLined ? <InfoOutlined /> : <Info />}
      </IconButton>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        sx={{ textAlign: "center" }}
        classes={{
          paper: "infoDilog",
        }}
      >
        <DialogTitle>
          <Avatar
            src={dynamicLink ? `${icon}` : `/static/icons/info_dialog/${icon}`}
            sx={{
              height: "90px",
              width: 100,
              m: "auto",
              mb: 2,
              mt: 2,
            }}
            alt="dialog_image"
            className="square"
          />
          {title}
        </DialogTitle>
        <DialogContent>
          <div
            // className="desciptionHtmlForm"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></div>
        </DialogContent>
        <DialogActions sx={{ display: "block" }}>
          <SubmitButton
            label={language.webInfo_ok}
            variant={language.webinfo_contained}
            onClick={handleClose}
            sx={{ width: "200px", m: 2, color: "#ffffff" }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

InformationDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  icon: PropTypes.string,
};

export default InformationDialog;
