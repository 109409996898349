import PageTitle from 'components/Common/PageTitle';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  pageTitle: string,
  children: ReactNode
}

const ContentHeaderLeft: FC<HeaderProps> = ({ pageTitle, children }) => {
  return (
    <>
      <PageTitle>
        {pageTitle}
      </PageTitle>
      { children }
    </>
  );
};

ContentHeaderLeft.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.node
};

export default ContentHeaderLeft;
