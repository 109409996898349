import {
  Button
} from '@material-ui/core';
import { useUtils } from 'hooks';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ButtonProps {
  label: string,
  to?: string,
  renderModel?: ReactNode,
  [key: string]: any
}

const LoadButton = ({ renderModel, label, onClick, to, ...other }) => {
  if (renderModel) {
    return (
      <Button
        color="primary"
        variant="outlined"
        onClick={onClick}
        className="outline_btn"
        {...other}
      >
        {label}
      </Button>
    );
  }
  if (to) {
    return (
      <Button
        color="primary"
        sx={{ m: 1 }}
        component={RouterLink}
        variant="outlined"
        to={to}
        className="outline_btn"
        {...other}
      >
        {label}
      </Button>
    );
  }
  return (
    <Button
      color="primary"
      sx={{ m: 1 }}
      className="outline_btn"
      variant="outlined"
      onClick={onClick}
      {...other}
      
    >
      {label}
    </Button>
  );
};

const OutlineButton: FC<ButtonProps> = ({ label, to, renderModel, ...other }) => {
  const { openModal, changeModelBody } = useUtils();
  return (
    <LoadButton
      renderModel={renderModel}
      label={label}
      onClick={other.onClick ? other.onClick : () => {
        openModal();
        changeModelBody(renderModel);
      }}
      to={to}
      {...other}
    />
  );
};

OutlineButton.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  renderModel: PropTypes.node
};

export default OutlineButton;
